<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  head: {
    title: 'OAuth',
  },
  form: {
    'clerk.btn_text': {
      type: 'text',
      column: 'is-12',
      label: 'Texte du bouton de connexion',
      tooltip: `
            Texte qui sera affiché sur le bouton de connexion via le SSO,
            visible sur la page de connexion de votre espace de formation
          `,
      tooltipAttrs: {
        position: 'is-left',
        multilined: true,
      },
      inputAttrs: {
        required: true,
      },
    },
    attach_all_trainings: {
      switchLabel: 'Inscrire automatiquement les nouveaux apprenants à toutes les formations',
      type: 'switch',
      column: 'is-12',
      tooltip: `
            En activant cette option, les nouveaux apprenants auront automatiquement accès
            à toutes les formations publiées, non répertoriées et cachées de votre espace au moment
            de leur inscription. Cette option n'aura aucun impact pour les apprenants existants.
          `,
      tooltipAttrs: {
        position: 'is-left',
        multilined: true,
      },
      inputAttrs: {
      },
    },
  },
  data() {
    return {
      store: {
        sso_options: {
          clerk: {
            btn_text: '',
          },
          attach_all_trainings: false,
        },
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
  },
};
</script>

<template>
<form
  ref="form"
  class="box"
  @submit.prevent="dataIsValid(isLoading) && handle()"
>
  <div class="columns is-multiline">
    <div class="column is-12">
      <p class="notification is-success">
        <b-icon icon="check" />
        Connexion OAuth par Clerk active
      </p>
    </div>
    <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
      <GeneratorField :fieldName="k" :fieldDefinition="field" :formData.sync="store.sso_options" />
    </div>
  </div>

  <b-field class="has-text-right">
    <b-button type="is-primary" native-type="submit" :loading="isLoading">
      Mettre à jour
    </b-button>
  </b-field>
</form>
</template>
